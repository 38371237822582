import styled from "styled-components"

export const StyledWhatSayAboutMeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
`
